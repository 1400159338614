<template>
  <div>
    <qriously :value="value" :size="size" />
    <span v-if="showText">{{ value }}</span>
  </div>
</template>

<script>
import VueQriously from "vue-qriously";
import Vue from "vue";
Vue.use(VueQriously);

export default {
  name: "QrCode",
  props: {
    value: { type: String, default: "" },
    size: { type: Number, default: 256 },
    showText: Boolean
  }
};
</script>

<style></style>
